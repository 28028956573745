<template>
  <div class="recharge-two-wapper" v-if="selectRechargeChannel">
    <actionList @submit="changeActiveActionIndex" :list="bankAll.action_list" :active="activeActionIndex"
      v-if="isActionList"></actionList>
    <template v-if="pageLoading">
      <div class="title-wapper" v-if="isSecond">
        <div class="tips_icon"></div>
        <span>{{
          selectRechargeChannel.pay_name || selectRechargeChannel.name
        }}</span>
      </div>

      <div class="title-wapper m-hide" v-else>
        <div class="tips_icon"></div>
        <span>{{ $t("充值信息") }}</span>
      </div>

      <div class="deposit_info_container">
        <div class="first" v-if="!isSecond">
          <div class="deposit_tips"> {{ $t("在存款之前可以查询符合资格的促销活动，如果您有任何存款方面的疑问，请立即联系客户服务。") }} </div>
          <div class="deposit_tips" v-if="selectRechargeChannel.is_online === 0 && $t('线下充值渠道子标题') !== '线下充值渠道子标题'"> {{ $t("线下充值渠道子标题")}} </div>
          <div class="title-wapper">
            <div class="tips_icon"></div>
            <span>{{ $t("充值渠道") }}</span>
          </div>
          <div class="channel_wrap">
            <div @click="selectChannel(item)" v-for="(item, index) in channelList" :key="index" class="channel_item"
              :class="{
                channel_item_active: channelId === item.id,
                channel_item_hot: item.isHot,
              }">
              <img v-if="item.is_hot" class="icon_hot" src="@/assets/images/icon_channel_hot.png" alt="" />
              <span>{{ item.pay_name || item.name }}</span>
            </div>
          </div>

          <div class="title-icon-wapper"></div>
          <div class="input-label" v-if="
            (selectRechargeChannel.pay_max_amout ||
              selectRechargeChannel.max_amcount) > 0
          ">
            {{ dw
            }}{{
              calc(
                selectRechargeChannel.pay_min_amout ||
                selectRechargeChannel.min_amcount
              )
            }}
            ~ {{ dw
            }}{{
              calc(
                selectRechargeChannel.pay_max_amout ||
                selectRechargeChannel.max_amcount
              )
            }}
            <div class="label-link" v-if="
              $t('recharge_video_' + selectRechargeChannel.id) !=
              'recharge_video_' + selectRechargeChannel.id
            " :href="$t('recharge_video_' + selectRechargeChannel.id)" @click="
              eduParentEvent($t('recharge_video_' + selectRechargeChannel.id))
              ">
              <span>{{ $t("充值教程") }}</span><span class="el-icon-arrow-right"></span>
            </div>
          </div>
          <div class="input-wapper">
            <el-input class="input" type="number" v-model="value" :placeholder="$t('请输入充值金额')">
              <span class="prefix-icon" slot="prefix">{{ dw }}</span>
              <span class="prefix-icon suffix-icon" slot="suffix" v-if="giftAmount">{{ $t("赠送") }} + {{ dw }} {{
                numFormat(giftAmount) }}</span>
            </el-input>
          </div>

          <div class="num-list">
            <div class="num-item" :class="{ num_item_active: item.num == value }" v-for="(item, index) in numList"
              :key="index" @click="tabNum(item.num)">
              <!-- <img src="@/assets/images/hot-icon2.png" class="hot-icon" alt="" v-if="item.isHot"> -->
              <template v-if="!checked">
                <div class="hot-wapper" v-if="item.giftAmount">
                  + {{ dw }}{{ item.giftAmount }}
                </div>
                <div class="hot-wapper" v-else-if="item.giftRatio">
                  + {{ item.giftRatio }}%
                </div>
                <div class="hot-wapper" v-else-if="item.isHot">HOT</div>
              </template>
              {{ dw }}{{ item.num }}
            </div>
          </div>

          <!-- 活动选择框 -->
          <!-- <div class="action-input num-item" v-if="isAction && $t('recharge_activity') != 'hide' && !checked"
            @click="isActionList = true">
            <div class="action-input-content">{{ activeAction.news_title }}</div>
            <i class="el-icon el-icon-caret-right"></i>
          </div> -->
          <div v-if="isAction && $t('recharge_activity') != 'hide' && !checked">
            <div class="title-wapper">
              <div class="tips_icon"></div>
              <span>{{ $t("选择促销") }}</span>
            </div>
            <div class="activity_container">
              <div class="activity_list">
                <div @click="changeActiveActionIndex(index)" v-for="(item, index) in bankAll.action_list" :key="index"
                  class="activity_item" :class="{ activity_item_active: activeActionIndex == index }">
                  <div class="activity_checkbox">
                    <img src="@/assets/images/icon_selected.png" alt="" />
                  </div>
                  <div class="activity_content">
                    {{ item.news_title }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 参与活动提示 -->
          <el-checkbox v-model="checked" class="checkbox" v-if="$t('recharge_activity') != 'hide'">
            <span :class="checked ? 'checked-ac' : 'checked'">{{
              $t("不参与本次优惠活动")
            }}</span>
          </el-checkbox>

          <div class="zh-input-wapper" v-if="
            value &&
            bankAll.currency_code !=
            selectRechargeChannel.currencyApi.currency_code
          ">
            <el-input class="input" :value="numFormat(value) + bankAll.currency_code" :readonly="true"
              :placeholder="$t('请输入充值金额')">
            </el-input>
            <el-input class="input" :value="calc(value, true) +
              selectRechargeChannel.currencyApi.currency_code
              " :readonly="true" :placeholder="$t('请输入充值金额')">
            </el-input>
          </div>

          <div class="deposit_btn_wrap">
            <el-button v-if="!qrcodeData" style="margin: 0" class="deposit_btn button_red" @click="submit"
              :loading="loading">
              <div class="btn-content">
                <span>{{ $t("充值") }}</span>
                <div>
                  <span>{{ calc(this.value, true) }}</span>
                  <span class="btn-dw">{{
                    selectRechargeChannel.currencyApi.currency_code
                  }}</span>
                  <i class="el-icon el-icon-arrow-right"></i>
                </div>
              </div>
            </el-button>
          </div>

          <div class="tip">
            <div class="tip-title">{{ $t("提示") }}</div>
            <div class="tip-item" v-if="$t('recharge_tip1') != 'recharge_tip1'">
              1.{{ $t("recharge_tip1") }}
            </div>
            <div class="tip-item" v-else>
              1.{{ $t("最低充值金额为") }}
              {{
                numFormat(
                  selectRechargeChannel.pay_min_amout ||
                  selectRechargeChannel.min_amcount
                )
              }}
              {{ selectRechargeChannel.currencyApi.currency_code }}
            </div>

            <div class="tip-item" v-if="$t('recharge_tip2') != 'recharge_tip2'">
              2.{{ $t("recharge_tip2") }}
            </div>
            <div class="tip-item" v-else>
              2.{{ $t("最高充值金额为") }}
              {{
                numFormat(
                  selectRechargeChannel.pay_max_amout ||
                  selectRechargeChannel.max_amcount
                )
              }}
              {{ selectRechargeChannel.currencyApi.currency_code }}
            </div>
            <div class="tip-item" v-if="$t('recharge_tip3') != 'recharge_tip3'">
              3.{{ $t("recharge_tip3") }}
            </div>
            <div class="tip-item" v-else-if="
              this.bankAll.currency_symbol !=
              selectRechargeChannel.currencyApi.currency_symbol
            ">
              3.{{ $t("今日汇率") }}：1{{
                selectRechargeChannel.currencyApi.currency_code
              }}
              = {{ numFormat(selectRechargeChannel.currencyApi.recharge_rate) }}
              {{ bankAll.currency_code }}
            </div>
          </div>
          <safe class="safe"></safe>
        </div>

        <!-- 第二步 -->
        <div class="second" v-else>
          <!-- 线下充值银行卡信息展示 -->
          <div class="bank-wapper">
            <template v-if="selectRechargeChannel.type == 1">
              <div class="bank-item">
                <div class="bank-title">
                  {{ $t("付款金额") }}
                </div>
                <div class="bank-content">
                  <span>{{ calc(value, true) }}
                    {{ selectRechargeChannel.currencyApi.currency_code }}</span>
                  <div v-clipboard:copy="calc(value, true)" v-clipboard:success="onCopy" class="copy-icon-wapper">
                    <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                  </div>
                </div>
              </div>
              <div class="bank-item">
                <div class="bank-title">
                  {{ $t("到账金额") }}
                </div>

                <div class="bank-content">
                  <span>{{ numFormat(value) }} {{ bankAll.currency_code }}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="bank-item" v-if="selectRechargeChannel.bank_name">
                <div class="bank-title">
                  {{
                    $t(
                      selectRechargeChannel.currencyApi.name == "USDT"
                        ? "链名称"
                        : "银行名称"
                    )
                  }}
                </div>
                <div class="bank-content">
                  <span>{{ selectRechargeChannel.bank_name }}</span>
                  <div v-clipboard:copy="selectRechargeChannel.bank_name" v-clipboard:success="onCopy"
                    class="copy-icon-wapper">
                    <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                  </div>
                </div>
              </div>
              <div class="bank-item" v-if="selectRechargeChannel.bank_number">
                <div class="bank-title">
                  {{
                    $t(
                      selectRechargeChannel.currencyApi.name == "USDT"
                        ? "钱包地址"
                        : "银行账户"
                    )
                  }}
                </div>
                <div class="bank-content">
                  <span>{{ selectRechargeChannel.bank_number }}</span>
                  <div v-clipboard:copy="selectRechargeChannel.bank_number" v-clipboard:success="onCopy"
                    class="copy-icon-wapper">
                    <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                  </div>
                </div>
              </div>
              <div class="bank-item" v-if="selectRechargeChannel.bank_khm">
                <div class="bank-title">{{ $t("户名") }}</div>
                <div class="bank-content">
                  <span>{{ selectRechargeChannel.bank_khm }}</span>
                  <div v-clipboard:copy="selectRechargeChannel.bank_khm" v-clipboard:success="onCopy"
                    class="copy-icon-wapper">
                    <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                  </div>
                </div>
              </div>
              <div class="bank-item" v-if="selectRechargeChannel.bank_open">
                <div class="bank-title">{{ $t("开户行") }}</div>
                <div class="bank-content">
                  <span>{{ selectRechargeChannel.bank_open }}</span>
                  <div v-clipboard:copy="selectRechargeChannel.bank_open" v-clipboard:success="onCopy"
                    class="copy-icon-wapper">
                    <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                  </div>
                </div>
              </div>
              <div class="bank-item">
                <div class="bank-title">
                  {{ $t("付款金额") }}
                </div>
                <div class="bank-content">
                  <span>{{ selectRechargeChannel.currencyApi.currency_symbol }}
                    {{ calc(value, true) }}</span>
                  <div v-clipboard:copy="calc(value, true)" v-clipboard:success="onCopy" class="copy-icon-wapper">
                    <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                  </div>
                </div>
              </div>
              <div class="bank-item">
                <div class="bank-title">
                  {{ $t("到账金额") }}
                </div>
                <div class="bank-content">
                  <span>{{ dw }} {{ numFormat(value) }}</span>
                  <div v-clipboard:copy="calc(value, true)" v-clipboard:success="onCopy" class="copy-icon-wapper">
                    <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div v-if="selectRechargeChannel.type == 1">
            <div class="code-wapper">
              <div class="qr-wapper">
                <img v-if="selectRechargeChannel.bank_qrcode" :src="selectRechargeChannel.bank_qrcode" class="vue-qr" />
                <vue-qr v-else class="vue-qr" :margin="10" :auto-color="true" :dot-scale="1" :text="selectRechargeChannel.bank_number || qrcodeData.address
                  " />
              </div>

              <div class="address-content">
                <span>{{
                  selectRechargeChannel.bank_number || qrcodeData.address
                }}</span>
                <div v-clipboard:copy="selectRechargeChannel.bank_number" v-clipboard:success="onCopy"
                  class="copy-icon-wapper">
                  <img src="@/assets/images/copy-icon.png" alt="" class="copy-icon4" />
                </div>
              </div>
              <div class="djs">{{ djs }}</div>
            </div>
          </div>

          <!-- 凭证 -->
          <div v-if="
            selectRechargeChannel.pay_type == 0 ||
            selectRechargeChannel.pay_type == 3 ||
            selectRechargeChannel.pay_type == 4 ||
            selectRechargeChannel.pay_type == 6
          ">
            <span class="upload-title">{{ $t("上传凭证") }}</span>
            <div class="upload-wapper">
              <el-upload class="avatar-uploader" :action="rootUrl + '/upload/img'" :show-file-list="false"
                :on-success="uploadSuccess" :headers="uploadHeader">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i class="el-icon-plus avatar-uploader-icon" v-else></i>
              </el-upload>
              <div class="upload-tip" v-if="!imageUrl">{{ $t('点击"+"上传的凭证') }}</div>
            </div>
          </div>

          <div class="deposit_btn_wrap">
            <el-button class="deposit_btn button_red" @click="back">
              <span class="btn-txt">{{ $t("上一步") }}</span>
              <img class="btn-icon" src="@/assets/images/sahngyibu.png" />
            </el-button>
            <el-button class="deposit_btn button_red" @click="submit" :loading="loading">
              <span class="btn-txt">{{ $t("已完成支付") }}</span>
              <img class="btn-icon" src="@/assets/images/wancheng.png" />
            </el-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { upload_api } from "@/api/index.js";
import safe from "./safe.vue";
import helper from "@/common/helper";
import { recharge_apply_api } from "@/api/user";
import VueQr from "vue-qr";
import actionList from "./actionList.vue";
export default {
  data() {
    return {
      activeActionIndex: 0,
      isActionList: false,
      timeId: null,
      djs: "10:00",
      uploadHeader: { usertoken: "", usertokenkey: "" },
      rootUrl: this.$helper.getApiUrl(),
      // numList:[50,100,200,500,1000,2000,5000,10000],
      loading: false,
      imageUrl: "",
      value: "",
      value2: "",
      checked: false,
      qrcodeData: null,
      isSecond: false,
      channelId: null,
      selectRechargeChannel: {},
      pageLoading: false,
    };
  },
  mounted() {
    this.isPhone();
    let { token, token_key } = helper.get("tokenInfo");
    this.uploadHeader.usertoken = token;
    this.uploadHeader.usertokenkey = token_key;
    this.$nextTick(() => {
      this.channelId = this.channelList[0]?.id;
      this.selectRechargeChannel = this.channelList[0];
      this.pageLoading = true;
    });
  },
  components: { safe, VueQr, actionList },
  props: {
    channelList: {
      default: () => [],
    },
    hide: {
      default: null,
    },
    depositInfo: {
      default: () => { },
    },
    // selectRechargeChannel: {
    //   default: () => { },
    // },
    bankAll: {
      default: () => { },
    },
  },
  computed: {
    isAction() {
      return (
        this.bankAll.action_list.length &&
        this.bankAll.action_is_force_join == 1
      );
    },
    activeAction() {
      if (this.bankAll.action_list.length) {
        return this.bankAll.action_list[this.activeActionIndex];
      }
    },
    dw() {
      return this.bankAll.currency_symbol;
    },

    /**
     * 计算赠送金额
     * */

    giftAmount() {
      if (!this.value || this.checked) return 0;
      const list = this.isAction
        ? this.activeAction.reg_json
        : this.bankAll.recharge_action_reg_json;
      const data = list.find((item) => {
        return (
          Number(this.value) <= Number(item.max_reg) &&
          Number(this.value) >= Number(item.min_reg)
        );
      });
      if (!data) return 0;
      if (data.type == 1) {
        return Number(data.bl_or_amount);
      } else if (data.type == 2) {
        return (this.value * data.bl_or_amount) / 100;
      }
    },

    /**
     * 格式化数字列表，寻找数字是否在热门里面
     * @returns {Array} 格式化好的数据
     * */

    numList() {
      const recharge_amount_discount = this.bankAll.recharge_amount_discount;
      const { bank_recharge_list, hot_bank_recharge_list } =
        this.bankAll.recharge_amount_sitting;

      // 过滤掉最小最大值
      const min = Number(this.selectRechargeChannel.pay_min_amout || this.selectRechargeChannel.min_amcount)
      const max = Number(this.selectRechargeChannel.pay_max_amout || this.selectRechargeChannel.max_amcount);
      const recharge_list = bank_recharge_list.filter(item => this.calc(item, true, true) <= max && this.calc(item, true, true) >= min);

      return recharge_list.map((item) => {
        const discount = recharge_amount_discount.find(
          (discountItem) => item == discountItem.amount
        );
        let giftRatio = 0,
          giftAmount = 0;
        if (discount) {
          if (discount.type == 1) {
            giftAmount = discount.bl_or_amount;
          } else {
            giftRatio = discount.bl_or_amount;
          }
        }
        // 如果是首冲的话，重新计算赠送金额
        if (this.isAction) {
          giftAmount = 0;
          giftRatio = 0;
          if (this.activeAction) {
            const regNum = this.activeAction.reg_json.find(
              (regItem) =>
                Number(item) <= Number(regItem.max_reg) &&
                Number(item) >= Number(regItem.min_reg)
            );
            if (regNum) {
              if (regNum.type == 1) {
                giftAmount = regNum.bl_or_amount;
              } else {
                giftRatio = regNum.bl_or_amount;
              }
            }
          }
          return {
            num: item,
            isHot: hot_bank_recharge_list.includes(item),
            giftRatio, //活动赠送金额比例
            giftAmount, //活动赠送金额
          };
        } else {
          return {
            num: item,
            isHot: hot_bank_recharge_list.includes(item),
            giftRatio, //活动赠送金额比例
            giftAmount, //活动赠送金额
          };
        }
      });
    },
  },
  // mounted() {
  //   this.$log.add("wallet_select_channel", this.selectRechargeChannel);
  // },
  methods: {
    selectChannel(item) {
      this.channelId = item.id;
      this.selectRechargeChannel = item;
    },
    changeActiveActionIndex(index) {
      this.activeActionIndex = index;
    },
    djsInit() {
      this.djs = "10:00";
      this.timeId = setInterval(() => {
        let s = this.djs.split(":")[0] * 60 + this.djs.split(":")[1] * 1;
        s--;
        if (s <= 0) {
          s = 600;
        }
        this.djs = `${parseInt(s / 60)}:${s % 60}`;
      }, 1000);
    },
    calc(num, isRes, isNumFormat) {
      const numChange = isRes
        ? num / this.selectRechargeChannel.currencyApi.recharge_rate
        : num * this.selectRechargeChannel.currencyApi.recharge_rate;
      if (isNumFormat) {
        return numChange.toFixed(3);
      } else {
        return this.numFormat(numChange);
      }
    },
    onInput(e) {
      this.value = this.clearIntegerNum(e);
    },
    onCopy: function () {
      this.successTips("Copy success");
    },
    uploadSuccess(e) {
      if (e.code == 1) {
        this.imageUrl = e.data.file;
      }
    },
    /**
     * 提交表单
     * */
    //  hide(){
    //   this.$emit('hide')
    //  },
    async submit() {
      const self = this;
      if (!this.value) {
        this.$message({
          showClose: true,
          message: this.$t("请输入充值金额"),
          type: "error",
        });
        return;
      }
      const max = this.selectRechargeChannel.pay_max_amout || this.selectRechargeChannel.max_amcount;
      const min = this.selectRechargeChannel.pay_min_amout || this.selectRechargeChannel.min_amcount;
      const calcAmcount = this.calc(this.value, true, true)
      if (calcAmcount > Number(max)) {
        this.$message({
          message: `${this.$t("单笔最高充值")} ${this.numFormat(max)} ${this.selectRechargeChannel.currencyApi.currency_code}`,
          type: "error",
        });
        return;
      }
      if (calcAmcount < Number(min)) {
        this.$message({
          message: `${this.$t("单笔最低充值")} ${this.numFormat(min)} ${this.selectRechargeChannel.currencyApi.currency_code}`,
          type: "error",
        });
        return;
      }

      // 判断是否显示二维码信息
      if (this.selectRechargeChannel.bank_number && this.isSecond == false) {
        // 如果线下收款账户包含https://，则直接跳转
        if (this.selectRechargeChannel.bank_number.includes('https://')) {
          const url = this.selectRechargeChannel.bank_number
          if (this.isMobileFlag) {
            window.location.assign(url);
          } else {
            window.open(url);
          }
          return
        }
        this.isSecond = true;
        this.$emit("nextStep");
        return;
      }

      // 判断是否为usdt第二步是的话隐藏
      if (this.qrcodeData && this.isSecond) {
        this.hide();
        return;
      }

      // 验证凭证
      let pay_type = this.selectRechargeChannel.pay_type;
      if (pay_type == 0 || pay_type == 3 || pay_type == 4 || pay_type == 6) {
        if (!this.imageUrl) {
          this.$message({
            showClose: true,
            message: this.$t("请上传充值凭证"),
            type: "error",
          });
          return;
        }
      }

      let form = {
        type: this.selectRechargeChannel.pay_type,
        bank_sk_id: this.selectRechargeChannel.id,
        apply_price: calcAmcount,
        credentials: this.imageUrl, //充值凭证（图片） type=0,3,4,6时必传
        usdt_type: this.selectRechargeChannel.usdt_type,
        not_auto_action: this.checked
          ? 1
          : this.isAction
            ? this.activeAction.id
            : 0,
      };
      // this.$log.add("recharge_click", form);
      this.loading = true;
      let res = await recharge_apply_api(form);
      this.loading = false;
      if (res.data.code == 1) {
        if (res.data.data.is_qrcode == 1) {
          this.qrcodeData = res.data.data;
          this.isSecond = true;
        } else if (res.data.data.handle_type == "navto") {
          self.loading = true;
          window.setTimeout(() => {
            self.loading = false;
          }, 3000);
          // this.$log.add("jump_three", { url: res.data.data.pay_url });
          if (this.isMobileFlag) {
            window.location.assign(res.data.data.pay_url);
          } else {
            window.open(res.data.data.pay_url);
          }
        } else {
          this.$message({
            showClose: true,
            message: this.$t("提交成功，等待后台审核"),
            type: "success",
          });
          this.hide && this.hide();
        }
      } else {
        this.$message({
          showClose: true,
          message: res.data.msg,
          type: "error",
        });
      }
    },

    /**
     * 返回上一步
     * */

    back() {
      this.isSecond = false;
      this.$emit("back");
    },

    /**
     * 数值按钮点击事件
     * @param {Number} num 点击的数字
     * */

    tabNum(num) {
      this.value = num;
    },
    eduParentEvent(val) {
      this.$emit("custom-event", val);
    },
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
  watch: {
    channelList(v) {
      if (v.length != 0) {
        this.channelId = v[0].id;
        this.selectRechargeChannel = v[0];
      } else {
        this.channelId = null;
      }
      this.isSecond = false;
    },
    value() {
      this.qrcodeData = null;
    },
    isSecond(v) {
      if (v) {
        this.djsInit();
      } else {
        this.qrcodeData = null;
        clearInterval(this.timeId);
      }
    },
  },
};
</script>

<style scoped lang="less">
.recharge-two-wapper {
  position: relative;
  border-radius: 10px;
  color: #fff;
  padding: 16px 16px 72px;
  overflow: hidden;
  height: 100%;

  @media (max-width: 768px) {
    height: auto;
    padding: 16px 16px 40px;
  }
}

.deposit_btn_wrap {
  position: absolute;
  width: 100%;
  height: 72px;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: #3a495f;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  gap: 16px;

  @media (max-width: 768px) {
    position: relative;
    flex-direction: column;
    background: transparent;
    height: auto;
    padding-right: 0;
  }
}

.deposit_btn {
  border-radius: 7px;
  margin-left: 0 !important;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.btn-content {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 16px;
  gap: 16px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.activity_container {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 4px 0 20px;
  }
}

.activity_list {
  width: 100%;
}

.activity_item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.activity_title {
  padding-top: 4px;
  min-width: fit-content;
  font-weight: 500;
  font-size: 14px;
  color: #8b8b8b;
  line-height: 14px;
}

.activity_checkbox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #e8f5fe;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: none;
  }
}

.activity_content {
  width: 100%;
  background: #285443;
  border-radius: 5px;
  border: 2px solid #36715a;
  padding: 8px;
}

.activity_item_active {
  .activity_checkbox {
    border: 2px solid #00ffca;

    img {
      display: inline-block;
    }
  }

  .activity_content {
    background: #00a885;
    border: 2px solid #00ffca;
  }
}

.title-wapper {
  padding: 4px 0;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    gap: 4px;
  }
}

.tips_icon {
  width: 5px;
  height: 16px;
  background-color: #3ad43d;

  @media (max-width: 768px) {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}

.deposit_info_container {
  padding-bottom: 90px;
  height: 100%;
  overflow-y: auto;
}

.deposit_tips {
  padding: 8px;
  border-radius: 5px;
  border: 2px solid #3a495f;
  background-color: #3a495f;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #54de6e;
    background: transparent;
    padding: 0;
  }
}

.copy-icon-wapper {
  cursor: pointer;
}

.copy-btn {
  display: none;
}

.el-icon-back {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(-10px, -50%);
  font-size: 20px;
  margin-bottom: 54px;
}

.input-wapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

/* .input {
  width: 254px;
} */
.input2 {
  width: 135px;
}

.el-icon-d-arrow-right {
  font-size: 20px;
}

.prefix-icon {
  transform: translate(-5px, 10px);
  color: #fff;
  line-height: 44px;
}

.input-label {
  margin-bottom: 14px;
  font-size: 14px;
}

.num-item {
  width: 75px;
  height: 44px;
  line-height: 44px;
  background-color: #3a495f;
  border-radius: 8px;
  text-align: center;
  /* margin-bottom: 13px; */
  cursor: pointer;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    background-color: transparent;
    border: 1px solid #3a495f;
  }
}

.num_item_active {
  color: #ffffff;
  background: linear-gradient(-1deg, #ff63f6, #ffdb4c);
}

/* .num-item:not(:nth-child(4n)) {
  margin-right: 12px;
} */

.hot-wapper {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ed1d49;
  color: #fff;
  line-height: 1em;
  border-radius: 0 0 0 100vh;
  padding: 4px 10px;
  padding-left: 14px;
  font-size: 12px;
  transform: scale(0.8);
  transform-origin: top right;
}

.num-item:hover {
  background-color: #3a495f;
}

.num-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 12px;
}

.btn {
  padding: 18px 0;
  overflow: hidden;
}

.btn-dw {
  margin-left: 10px;
}

.el-icon-arrow-right {
  margin-left: 4px;
}

.tip-item {
  color: #fff;
  margin-left: 19px;
  font-size: 12px;
  margin-bottom: 8px;
}

.tip-title {
  margin-top: 19px;
  margin-bottom: 11px;
}

.tip {
  margin-bottom: 50px;
}

.bank-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
}

.address-content {
  width: 100%;
  max-width: 420px;
  background: #161f2c;
  border-radius: 5px;
  border: 2px solid #2a3546;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  flex-shrink: 0;
  gap: 6px;
}

.bank-content {
  height: 42px;
  background: #161f2c;
  border-radius: 5px;
  border: 2px solid #2a3546;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
  flex-shrink: 0;
  margin-top: 6px;
}

.bank-content span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  width: 0;
}

.copy-icon4 {
  width: 22px;
  cursor: pointer;
}

.bank-title {
  min-width: 65px;
}

.bank-wapper {
  margin-bottom: 30px;
  padding-right: 10px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.vue-qr {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.code-wapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  position: relative;
  align-items: center;
}

.code-input {
  width: 80%;
  background: #009a7a;
  border: 2px solid #2c3748;
  border-radius: 9px;
  color: #ffffff;
  font-size: 14px;
  padding: 8px;
  position: relative;
  min-height: 5em;
}

.copy-icon {
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 999;
}

.copy-icon img {
  width: 100%;
}

.qr-wapper {
  width: 176px;
  height: 176px;
  flex-shrink: 0;
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #161f2c;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #a6a6a6;
  width: 91px;
  height: 91px;
  line-height: 91px;
  text-align: center;
  border: 2px solid #a6a6a6;
  border-radius: 4px;
}

.avatar {
  width: 91px;
  height: 91px;
  display: block;
  object-fit: cover;
}

.upload-title {
  margin-bottom: 12px;
  display: block;
}

.upload-wapper {
  padding: 14px 18px;
  border: 2px solid #a6a6a6;
  border-radius: 8px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

.upload-tip {
  margin-left: 20px;
  color: #a6a6a6;
  font-size: 14px;
}

::v-deep .el-icon-loading {
  position: relative;
  left: 16px;
  top: 4px;
}

.suffix-icon {
  font-size: 12px;
  color: #00ff40;
  animation: suffixIco 1s infinite;
}

.copy-icon1 {
  width: 16px;
  height: 16px;
  margin-left: 14px;
  margin-bottom: 6px;
  cursor: pointer;
}

@keyframes suffixIco {
  0% {
    text-shadow: 0 0 0 #00ff40;
  }

  50% {
    text-shadow: 0 0 10px #00ff40;
  }

  100% {
    text-shadow: 0 0 0 #00ff40;
  }
}

/* .input ::v-deep .el-input__inner {
  padding-right: 100px;
} */
.checkbox {
  margin-bottom: 20px;
}

::v-deep .el-checkbox__inner {
  border-radius: 50%;
  border-color: #1fd872 !important;
  border: 2px solid #1fd872;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #da0043 !important;
  border-color: #da0043 !important;
  border: 1px solid #da0043;
}

.checked {
  color: #1fd872;
}

.checked-ac {
  color: #da0043;
}

.code-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.label-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin-left: 5px;
  color: #1fd872;
  line-height: 1;
  cursor: pointer;
}

.label-link .el-icon-arrow-right {
  animation: rightpulse 1s ease-in-out alternate infinite;
}

@keyframes rightpulse {
  0% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

.btn-icon {
  width: 1em;
  margin-left: 10px;
}

.btn-txt {
  display: inline-block;
  min-width: 6em;
}

.suffix-btn {
  display: block;
  background: #3a495f;
  border-radius: 8px;
  margin-top: 4px;
  height: 36px;
  width: 76px;
  line-height: 36px;
  color: #fff;
}

.zh-input-wapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.zh-input-wapper .input:last-child {
  margin-left: 4px;
}

.djs {
  color: red;
  font-size: 26px;
  margin-top: 10px;
}

.copy-icon-input {
  width: 44px;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
}

.action-input {
  background-color: #3a495f;
  margin-bottom: 20px;
  height: 55px;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  white-space: wrap;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-right: 26px;
  position: relative;
}

.el-icon-caret-right {
  position: absolute;
  right: 10px;
}

.action-input-content {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 这里是超出几行省略 */
}

@media (max-width: 768px) {
  .action-input {
    height: 44px;
  }
}

.safe {
  margin-top: 60px;
}

.channel_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  cursor: pointer;
  gap: 12px;
}

.channel_item {
  position: relative;
  padding: 10px 16px;
  font-size: 14px;
  background: #3a495f;
  border-radius: 7px;
}

.channel_item span {
  position: relative;
  z-index: 2;
}

.channel_item_active {
  color: #ffffff;
  background: linear-gradient(-1deg, #ff63f6, #ffdb4c);
}

.channel_item_hot {
  padding-left: 28px;
}

.icon_hot {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px !important;
  height: auto !important;
  object-fit: cover;
}

::-webkit-scrollbar {
  width: 0;
}

.m-hide {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
